/*!

=========================================================
* BLK Design System React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import Logo from "components/logo"
// reactstrap components
import {
  Button,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  UncontrolledTooltip
} from "reactstrap";

import { useEffect, } from "react";
import { gsap } from "gsap/all";
import { ScrollbarContext } from "components/contexts";

export default function IndexNavbar(props) {
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  const [collapseOut, setCollapseOut] = React.useState("");
  const [color, setColor] = React.useState("navbar-transparent");
  const bar = useContext(ScrollbarContext)
  const history = useHistory();

  useEffect(() => {
    const base_delay = props.fixed ? 0.5 : 0.2;
    const a1 = gsap.from(".nav-product", { x: -60, duration: 1, opacity: 0, delay: base_delay + .5 });
    const a2 = gsap.from(".nav-security", { x: -60, duration: 1, opacity: 0, delay: base_delay + .75 });
    const a3 = gsap.from(".nav-about", { x: -60, duration: 1, opacity: 0, delay: base_delay + 1 });
    const a4 = gsap.from(".nav-contact", { x: -60, duration: 1, opacity: 0, delay: base_delay + 1.25 });
    return () => {
      a1.kill();
      a2.kill();
      a3.kill();
      a4.kill();
    };
  }, [])

  React.useEffect(() => {
    if (props.fixed) {
      setColor("navbar-opacity");

    } else
      if (bar)
        bar.addListener((status) => {
          if (
            bar.scrollTop > 99 ||
            bar.scrollTop > 99
          ) {
            setColor("navbar-opacity");
          } else if (
            bar.scrollTop < 100 ||
            bar.scrollTop < 100
          ) {
            setColor("navbar-transparent");
          }
        });
  }, [bar]);

  const toggleCollapse = () => {
    document.documentElement.classList.toggle("nav-open");
    setCollapseOpen(!collapseOpen);

    const a1 = gsap.from(".nav-product", { x: -60, duration: .5, opacity: 0, delay: 0 });
    const a2 = gsap.from(".nav-security", { x: -60, duration: .5, opacity: 0, delay: .1 });
    const a3 = gsap.from(".nav-about", { x: -60, duration: .5, opacity: 0, delay: .2 });
    const a4 = gsap.from(".nav-contact", { x: -60, duration: .5, opacity: 0, delay: .3 });
  };
  const onCollapseExiting = () => {
    setCollapseOut("collapsing-out");
  };
  const onCollapseExited = () => {
    setCollapseOut("");
  };

  return (
    <Navbar className={"fixed-top " + color} color-on-scroll="100" expand="lg">
      <Container>
        <div className="navbar-translate">
          {/* <NavbarBrand to="/" tag={Link} id="navbar-brand">
            Dailir 
          </NavbarBrand> */}
          <button
            aria-expanded={collapseOpen}
            className="navbar-toggler navbar-toggler"
            onClick={toggleCollapse}
          >
            <span className="navbar-toggler-bar bar1" />
            <span className="navbar-toggler-bar bar2" />
            <span className="navbar-toggler-bar bar3" />
          </button>
        </div>
        <Collapse
          className={"justify-content-end " + collapseOut}
          navbar
          isOpen={collapseOpen}
          onExiting={onCollapseExiting}
          onExited={onCollapseExited}
        >
          <div className="navbar-collapse-header">
            <Row>
              <Col className="collapse-brand" xs="6">
                {/* <a href="#pablo" onClick={(e) => e.preventDefault()}>
                  Dailir
                </a> */}
                <Logo></Logo>
              </Col>
              <Col className="collapse-close text-right" xs="6">
                <button
                  aria-expanded={collapseOpen}
                  className="navbar-toggler close"
                  onClick={toggleCollapse}
                >
                  <i className="tim-icons icon-simple-remove" />
                </button>
              </Col>
            </Row>
          </div>
          <Nav navbar>
            <NavItem className="p-0 nav-product">
              <NavLink
                className="hover-1"
                data-placement="bottom"
                href="#"
                onClick={() => history.push('/product')}
              >
                Product
              </NavLink>
            </NavItem>
            {/* <NavItem className="p-0 nav-security">
              <NavLink
                className="hover-1"
                data-placement="bottom"
                href="#"
                onClick={() => history.push('/security')}
              >
                Security
              </NavLink>
            </NavItem> */}
            {/* <NavItem className="p-0 nav-about">
              <NavLink
                className="hover-1"
                data-placement="bottom"
                href="#"
                onClick={() => history.push('/about')}
              >
                About
              </NavLink>
            </NavItem> */}
            <NavItem className="p-0 nav-contact">
              <NavLink
                className="hover-1"
                data-placement="bottom"
                href="#"
                onClick={() => history.push('/contact')}
              >
                Contact
              </NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
}
