/*!

=========================================================
* BLK Design System React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useContext } from "react";
import StackLayer from "components/PageHeader/StackLayer"
import Logo from "components/logo"
import { useEffect, useLayoutEffect, useRef } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Label,
  FormGroup,
  Form,
  Input,
  FormText,
  NavItem,
  NavLink,
  Nav,
  Table,
  TabContent,
  TabPane,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  UncontrolledCarousel
} from "reactstrap";

import { gsap, ScrollTrigger } from "gsap/all";
import ProductProfile from "components/Sections/ProductProfile"
import ProductPlatform from "components/Sections/ProductPlatform";
import ProductReady from "components/Sections/ProductReady";
import { ScrollbarContext, isTouchScreenDevice } from 'components/contexts'

gsap.registerPlugin(ScrollTrigger)

function createHeaderAnimation() {

  let textAnimation = gsap.timeline();
  // textAnimation.set('body', { className: 'disable-scroll index-page' })
  textAnimation.from('.t-word', {
    y: 100,
    opacity: 0,
    duration: .8,
    stagger: {
      each: 0.4
    }
  });
  textAnimation.from(".stack-layer-2",
    { opacity: 0, y: -500, x: 0, duration: 1.5, ease: 'circ', delay: .0 }, '>');
  textAnimation.from(".stack-layer-1",
    { opacity: 0, y: -500, x: 0, duration: 1.5, ease: 'circ', delay: .2 }, '<');
  textAnimation.from(".stack-layer-0",
    { opacity: 0, duration: 1.5, x: 0, ease: 'circ', delay: .6 }, '<');
  textAnimation.from('.header-logo', {
    opacity: 0,
    duration: 1,
    delay: .3,
  }, '<')

  gsap.timeline({
    ease: 'none',
    scrollTrigger: {
      trigger: ".stack-layer",
      start: "top top+=50px",
      end: "+=5000",
      scrub: true,
      scroller: '.viewport-container',
      // markers: true,
      pin: true,
      pinType: isTouchScreenDevice() ? 'fixed' : 'transform',
    }
  })
    //   // transitions
    //   // .fromTo(".brand", { opacity: 1 }, { opacity: 0, duration: .1 }, 0)
    //   // .fromTo(".t-word", { y: 0 }, { y: 100, duration: .1 }, 0)
    .set(".stack-layer-0, .stack-layer-1, .stack-layer-2", { x: 0 })
    .fromTo(".stack-layer", { opacity: 1 }, { opacity: .4, duration: .1 }, 0)
    //   // .fromTo(".stack-layer-1", { y: 0 },
    //   //   { y: -60, duration: .3, }, 0)
    //   // .fromTo(".stack-layer-2", { y: 0 },
    //   //   { y: -120, duration: .3, }, 0)
    //   // .fromTo(".stack-layer", { x: 0 },
    //     // { x: -300, scale: 3, duration: .8, }, '>')
    .to(".stack-layer-0",
      { x: -300, scale: 3, duration: .8, }, '<')
    .to(".stack-layer-1",
      { x: -300, scale: 3, duration: .8, }, '<')
    .to(".stack-layer-2",
      { x: -300, scale: 3, duration: .8, }, '<')
    //   // start new sections
    .fromTo(".stack-layer-0", { x: -300 },
      { x: -300, duration: 3, }, '>')
    .fromTo(".stack-layer-1", { x: -300 },
      { x: -300, duration: 3, }, '<')
    .fromTo(".stack-layer-2", { x: -300 },
      { x: -300, duration: 3, }, '<');
}

function createProductProfileAnimation() {
  // gsap.to(".stack-layer-2 .path-0", {
  //   fill: '#ff000096', scrollTrigger: {
  //     trigger: '.product-1', start: "top center",
  //     scroller: '.viewport-container',
  //   }
  // })

  // let textAnimation = gsap.timeline({
  //   scrollTrigger: {
  //     trigger: ".product-1 .phone-background",
  //     start: "top-=400px center",
  //     scroller: '.viewport-container',
  //   }
  // });

  gsap.from('.product-1 .phone-background', {
    scrollTrigger: {
      trigger: ".product-1 .phone-background",
      start: "top-=200px center",
      scroller: '.viewport-container',
    },
    scale: 1.3,
    opacity: 0,
    duration: 1,
    transformOrigin: 'top right',
  })

  // textAnimation.from('.product-1 .App-Title', { duration: 1, translateX: -100 }, 0);
  // textAnimation.from('.product-1 .Cloudy', { duration: 1, translateX: 100 }, 0.5);
  // textAnimation.from('.product-1 .Transactions', { duration: 1, translateX: -100 }, .55);
  // textAnimation.from('.product-1 .Card-All', { duration: 1, translateX: 100 }, 1.05);
  // textAnimation.from('.product-1 .Limit-0', { duration: 1, translateX: -100 }, 1.55);
  // textAnimation.from('.product-1 .Limit-1', { duration: 1, translateX: -100 }, 1.5);
  // textAnimation.from('.product-1 .Send-All', { duration: 1, translateY: 200 }, 2);


  let textAnimation2 = gsap.timeline({
    scrollTrigger: {
      trigger: ".product-background .features",
      start: "top-=200px center",
      scroller: '.viewport-container',
      // markers: true
      // end: "bottom+=200px bottom",
    }
  });
  textAnimation2.from('.product-1 .feature-1 .feature-wrapper h3', { y: 60, duration: .5 }, 0);
  textAnimation2.from('.product-1 .feature-1 p', { opacity: 0, duration: 1 }, 0.5);
  textAnimation2.from('.product-1 .feature-2 .feature-wrapper h3', { y: 60, duration: .5 }, 0.4);
  textAnimation2.from('.product-1 .feature-2 p', { opacity: 0, duration: 1 }, 0.9);
  textAnimation2.from('.product-1 .feature-3 .feature-wrapper h3', { y: 60, duration: .5 }, 0.8);
  textAnimation2.from('.product-1 .feature-3 p', { opacity: 0, duration: 1 }, 1.3);
  textAnimation2.fromTo('.product-1 .feature-1  hr', { width: '0%' }, { width: '40%', duration: 1, }, 1.6)
  textAnimation2.fromTo('.product-1 .feature-2  hr', { width: '0%' }, { width: '40%', duration: 1, }, 1.8)
  textAnimation2.fromTo('.product-1 .feature-3  hr', { width: '0%' }, { width: '40%', duration: 1, }, 2)

  let textAnimation3 = gsap.timeline({
    scrollTrigger: {
      trigger: ".product-lock .features",
      start: "top-=200px center",
      scroller: '.viewport-container',
    }
  });

  textAnimation3.from('.product-1 .feature-4 .feature-wrapper h3', { y: 60, duration: .5 }, 0);
  textAnimation3.from('.product-1 .feature-4 p', { opacity: 0, duration: 1 }, 0);

  // let textAnimation4 = gsap.timeline({
  //   scrollTrigger: {
  //     trigger: ".product-1 .phone-lock",
  //     start: "top-=200px center",
  //     scroller: '.viewport-container',
  //   }
  // });
  // textAnimation4.from('.product-1 .phone-lock', { duration: .5, opacity: 0 }, 0.4);
  gsap.from('.product-1 .phone-lock', {
    scrollTrigger: {
      trigger: ".product-1 .phone-lock",
      start: "top-=200px center",
      scroller: '.viewport-container',
    },
    scale: 1.3,
    opacity: 0,
    duration: 1,
    transformOrigin: 'top left',
  })
}

function createProductPlatformAnimation() {
  // gsap.to(".stack-layer-1 .path-0", {
  //   fill: '#ff000096', scrollTrigger: {
  //     trigger: '.product-2', start: "top center",
  //     scroller: '.viewport-container',
  //   }
  // })


  // let textAnimation = gsap.timeline({
  //   // ease: 'circ',
  //   scrollTrigger: {
  //     trigger: ".product-platform .phone",
  //     start: "top-=200px center",
  //     scroller: '.viewport-container',
  //   }
  // });

  // textAnimation.from('.product-platform .client-interest', { duration: 1, translateX: 100 }, 0.5);
  // textAnimation.from('.product-platform .value-chart', { duration: 1, opacity: 0, translateY: 30 }, 1);
  // textAnimation.from('.product-platform .total-value', { duration: 1, opacity: 0 }, 1.4);
  // textAnimation.from('.product-platform .market-cap', { duration: 1, translateX: 100 }, 1.4);
  // textAnimation.from('.product-platform .engage-button', { duration: 1, translateY: 30 }, 2);

  let textAnimation2 = gsap.timeline({
    // ease: 'circ',
    scrollTrigger: {
      trigger: ".product-dash .features",
      start: "top center",
      scroller: '.viewport-container',
      // end: "bottom+=200px bottom",
    }
  });
  textAnimation2.from('.product-2 .feature-1 .feature-wrapper h3', { y: 60, duration: .5 }, 0);
  textAnimation2.from('.product-2 .feature-1 p', { opacity: 0, duration: 1 }, 0.5);
  textAnimation2.from('.product-2 .feature-2 .feature-wrapper h3', { y: 60, duration: .5 }, 0.4);
  textAnimation2.from('.product-2 .feature-2 p', { opacity: 0, duration: 1 }, 0.9);


  gsap.from('.product-dash .phone', {
    scrollTrigger: {
      trigger: ".product-dash .phone",
      start: "top-=200px center",
      scroller: '.viewport-container',
    },
    scale: 1.3,
    opacity: 0,
    duration: 1,
    transformOrigin: 'top right',
  })

  gsap.from('.product-platform .phone', {
    scrollTrigger: {
      trigger: ".product-platform .phone",
      start: "top-=200px center",
      scroller: '.viewport-container',
    },
    scale: 1.3,
    opacity: 0,
    duration: 1,
    transformOrigin: 'top left',
  })
  // let textAnimation3 = gsap.timeline({
  //   // ease: 'circ',
  //   scrollTrigger: {
  //     trigger: ".product-dash .phone",
  //     start: "top center",
  //     scroller: '.viewport-container',
  //     // end: "bottom-=200px center",
  //     // markers: true,
  //     // scrub: true,
  //   }
  // });

  // textAnimation3.fromTo('.product-dash .Trending-Collections', { translateX: 300 }, { translateX: -30, duration: 1.5 }, 0);
  // textAnimation3.fromTo('.product-dash .Top-Seller', { translateX: -300 }, { translateX: 30, duration: 1.5 }, 0);

  let textAnimation4 = gsap.timeline({
    // ease: 'circ',
    scrollTrigger: {
      trigger: ".product-platform .features",
      start: "top-=200px center",
      scroller: '.viewport-container',
      // end: "bottom+=200px bottom",
      // markers: true,
      // scrub: true,
      // pin: true,
    }
  });

  textAnimation4.from('.product-2 .feature-3 .feature-wrapper h3', { y: 60, duration: .5 }, 0);
  textAnimation4.from('.product-2 .feature-3 p', { opacity: 0, duration: 1 }, 0.5);
}

function createProductReadyAnimation() {
  gsap.from(".product-ready .ready-left h1", {
    y: 60, stagger: .3, scrollTrigger: {
      trigger: '.product-ready .ready-left', start: "top center+=200px",
      scroller: '.viewport-container',
    }
  })

  gsap.to(".product-ready .ready-left", {
    y: -200, stagger: .3, scrollTrigger: {
      scrub: true,
      trigger: '.product-ready ', start: "top center+=300px",
      scroller: '.viewport-container',
    }
  })
}

export default function PageHeader() {
  const el = useRef();
  const q = gsap.utils.selector(el);
  const tl = useRef();
  const bar = useContext(ScrollbarContext)


  useLayoutEffect(() => {
    if (bar)
      bar.addListener((status) => {
        ScrollTrigger.update()
      });
    else if (isTouchScreenDevice()) {
      window.addEventListener('scroll', () => {
        ScrollTrigger.update()
      }, { passive: true });

    }

    if (bar) {
      ScrollTrigger.scrollerProxy(".viewport-container", {
        scrollTop(value) {
          if (arguments.length) {
            if (bar)
              bar.scrollTop = value;
          }
          if (bar)

            return bar.scrollTop;
          return 0;
        },
        getBoundingClientRect() {
          return {
            top: 0,
            left: 0,
            width: window.innerWidth,
            height: window.innerHeight
          };
        }
      });

    }

    if (bar || isTouchScreenDevice()) {
      createHeaderAnimation()
      // createProductProfileAnimation()
      // createProductPlatformAnimation()
      // createProductReadyAnimation()
    }



    return () => {
      if (bar)
        ScrollTrigger.scrollerProxy(null);
    }
  }, [bar])


  return (
    <>
      <div className="page-header header-filter">
        {/* <div className="squares square1" /> */}
        {/* <div className="squares square2" /> */}
        {/* <div className="squares square3" /> */}
        {/* <div className="squares square4" /> */}
        {/* <div className="squares square5" /> */}
        {/* <div className="squares square6" /> */}
        {/* <div className="squares square7" /> */}

        <Container>
          <div className="stack-layer">
            <StackLayer className="stack-layer-single stack-layer-0"></StackLayer>
            <StackLayer className="stack-layer-single stack-layer-1"></StackLayer>
            <StackLayer className="stack-layer-single stack-layer-2"></StackLayer>
          </div>
          <div className="content-center brand">
            <Logo className='header-logo'></Logo>
            <div className="title-desp" ref={el}>
              <div className="t-word-wrapper">
                <div className="t-word">Creating Frictionless Relationships&nbsp;</div>
              </div>
              {/*<div className="t-word-wrapper">
                <div className="t-word">between Restaurants&nbsp;</div>
              </div>
              <div className="t-word-wrapper">
                <div className="t-word">and Patrons&nbsp;</div>
              </div>*/}
            </div>
          </div>
          {/* <img alt="..." className="path bg-1" src={require("assets/img/path4.png")} /> */}
          {/* <img alt="..." className="path bg-2" src={require("assets/img/path3.png")} />
          <img alt="..." className="path bg-3" src={require("assets/img/path1.png")} /> */}
        </Container>
        <div className="sections">
          {/* <ProductProfile></ProductProfile> */}
          {/* <ProductPlatform></ProductPlatform> */}
          {/* <ProductReady></ProductReady> */}
        </div>
      </div>
    </>
  );
}
