/*!

=========================================================
* BLK Design System React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useContext } from "react";
import StackLayer from "components/PageHeader/StackLayer"
import Logo from "components/logo"
import { useEffect, useLayoutEffect, useRef } from "react";
import Scrollbar from 'smooth-scrollbar';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Label,
  FormGroup,
  Form,
  Input,
  FormText,
  NavItem,
  NavLink,
  Nav,
  Table,
  TabContent,
  TabPane,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  UncontrolledCarousel
} from "reactstrap";

import { gsap, ScrollTrigger } from "gsap/all";
import { ScrollbarContext, isTouchScreenDevice } from 'components/contexts'

gsap.registerPlugin(ScrollTrigger)

function createHeaderAnimation() {
  gsap.from('.page-about ', {
    opacity: 0
  })
  gsap.from('.feature-1 .feature-wrapper h3', { y: -80, duration: 1, delay: 1 })
  gsap.from('.feature-1 .feature-wrapper h1', { y: -220, duration: 1, delay: 1.6 })
  gsap.from('.feature-1 .feature-wrapper p', { opacity: 0, duration: 1, delay: 2.3 })
  gsap.from('.section-1 ', { scale: 1.3, duration: 1, delay: .2 })

  gsap.fromTo('.section-1 ', {
    backgroundPositionY: 80
  }, {
    scrollTrigger: {
      trigger: '.section-1',
      start: 'top top',
      ends: 'bottom bottom',
      scrub: true,
      scroller: '.viewport-container'
    },
    backgroundPositionY: 10
  })

  gsap.timeline({
    scrollTrigger: {
      trigger: '.feature-1',
      start: 'bottom center',
      scroller: '.viewport-container',
    }
  }).from(
    '.feature-2 .feature-wrapper h2', { y: 80, duration: .6 }, 0)
    .from(
      '.feature-2 p', { opacity: 0, duration: .6 }, .5)
    .from(
      '.feature-2 .profiles a', { y: 100, opacity: 0, stagger: .2, scale: 1.2, duration: 1 }, .5
    ).set(
      '.feature-2 .profiles a', { className: 'animated' }
    )
}


export default function PageHeader() {
  const el = useRef();
  const q = gsap.utils.selector(el);
  const tl = useRef();
  const bar = useContext(ScrollbarContext)


  useLayoutEffect(() => {
    if (bar)
      bar.addListener((status) => {
        ScrollTrigger.update()
      });

    if (bar) {
      ScrollTrigger.scrollerProxy(".viewport-container", {
        scrollTop(value) {
          if (arguments.length) {
            if (bar)
              bar.scrollTop = value;
          }
          if (bar)

            return bar.scrollTop;
          return 0;
        },
        getBoundingClientRect() {
          return {
            top: 0,
            left: 0,
            width: window.innerWidth,
            height: window.innerHeight
          };
        }
      });

    }

    if (bar || isTouchScreenDevice())
      createHeaderAnimation()

    return () => {
      if (bar)
        ScrollTrigger.scrollerProxy(null);
    }
  }, [bar])


  // useEffect(() => {
  //   const scroller = document.querySelector(".data-flow-chart");
  //   Scrollbar.init(scroller);
  // }, []);



  return (
    <>
      <div className=" page-about">
        <Container className="section-1">
          <Row className=" feature-1">
            <Col lg='1' className="">
            </Col>
            <Col lg='5' className="">
              <div className="feature-wrapper">
                <h3 >Dailir Inc.</h3>
              </div>
              <div className="feature-wrapper">
                <h1>For Frictionless Financial Relationships</h1>
              </div>
              <div className="feature-wrapper">
                <p >Dailir's technology makes it simple for: wealth managers to create better client relationships; investors to protect their patrimonies and enjoy better financial services. We work with and empower wealth managers and investors alike to create meaningful relationships and facilitate the transfer of information.
                  Dailir enables frictionless digital information sharing to reduce time and costs in information obtention for financial actors and their clients.
                </p>
              </div>
            </Col>
            <Col lg='7'>
            </Col>
          </Row>
          <Row className=" feature-2">
            <Col lg='2'></Col>
            <Col lg='6'>
              <div className="feature-wrapper">
                <h2 >Meet our team</h2>
              </div>
              <div className="feature-wrapper">
                <p >Innovators passionate about creating frictionless financial relationships.
                  Our multidisciplinary team is composed of specialists in: law, cybersecurity, AI, and accounting
                </p>
              </div>
            </Col>
            <Col lg='4'></Col>
            <Col lg='2' className="">
            </Col>
            <Col lg='8' className="">

              <section class="profiles">
                <a ><img src={require('assets/img/I-Sacha.png')} alt="" />
                  <div>
                    <h3>Sacha Alaby</h3>
                    <h4>B.C.L. & LL.B. (J.D.), Law, McGill</h4>
                    <h4>Business Development, Legal</h4>
                  </div>
                </a>
                <a ><img src={require('assets/img/I-Reggie.png')} alt="" />
                  <div>
                    <h3>Réginald Pierre-Louis</h3>
                    <h4>CPA, MBA, M. Fisc.</h4>
                    <h4>Accounting, Tax, Administrative</h4>
                  </div>
                </a>
                <a ><img src={require('assets/img/I-Steven.png')} alt="" />
                  <div>
                    <h3>Steven Ding</h3>
                    <h4>PhD, McGill</h4>
                    <span>Security, Research, Product</span>
                  </div>
                </a>

                <a ><img src={require('assets/img/I-Ola.png')} alt="" />
                  <div>
                    <h3>Olawale Moses Onabola</h3>
                    <h4>BSc, ex-MILA</h4>
                    <h4>Product, Operations, Development</h4>
                  </div>
                </a>
              </section>
            </Col>
            <Col lg='2' className="">
            </Col>
          </Row>

        </Container>
      </div>
    </>
  );
}
