/*!

=========================================================
* BLK Design System React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Switch, Redirect, useLocation, Router } from "react-router-dom";

import "assets/css/nucleo-icons.css";
import "assets/scss/blk-design-system-react.scss";
import "assets/demo/demo.css";
import "assets/demo/custom.scss";
import "assets/demo/block-chain.scss";

import Index from "views/Index.js";
import SecurityPage from "views/Security.js";
import ContactPage from "views/Contact";
import AboutPage from "views/About.js";
import RegisterPage from "views/examples/RegisterPage";
import {
  TransitionGroup,
  CSSTransition
} from "react-transition-group";

const root = ReactDOM.createRoot(document.getElementById("root"));

function PageTransitionApp() {
  const location = useLocation()

  return (
    <Switch location={location}>
      <Route path="/product" render={(props) => <Index {...props} />} />
      <Route path="/contact" render={(props) => <ContactPage {...props} />} />
      {/* <Route path="/security" render={(props) => <SecurityPage {...props} />} /> */}
      {/* <Route path="/about" render={(props) => <AboutPage {...props} />} /> */}
      <Redirect from="/" to="/product" />
    </Switch>

  );
}

export default function App() {
  return (
    <BrowserRouter basename="/#/">
      <PageTransitionApp></PageTransitionApp>
    </BrowserRouter>
  )
}


root.render(
  <App />
)
