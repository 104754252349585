import { useEffect, useRef, useContext, createContext } from "react";
import { ScrollTrigger, gsap } from "gsap/all";
import Scrollbar from 'smooth-scrollbar';

export const isTouchScreenDevice = () => {
    try {
        document.createEvent('TouchEvent');
        return true;
    } catch (e) {
        return false;
    }
}

export const ScrollbarContext = createContext(null)