

export default (props) => {
    return <>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 326 233" className={props.className}>
            <g filter="url(#a)">
                <path className="path-0" fill="url(#b)" d="M0 0h156.675v156.675H0z" transform="matrix(.86603 .5 -.86603 .5 162.684 0)" />
                <path className="path-1" fill="url(#c)" d="M0 0h156.675v156.675H0z" transform="matrix(.86603 .5 -.86603 .5 162.684 0)" />
            </g>
            <defs>
                <linearGradient id="b" x1="0" x2="156.675" y1="0" y2="156.675" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#C4C4C4" stop-opacity=".22" />
                    <stop offset=".842" stop-color="#C4C4C4" stop-opacity="0" />
                </linearGradient>
                <linearGradient id="c" x1="0" x2="156.675" y1="0" y2="156.675" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#6200EE" stop-opacity=".74" />
                    <stop offset=".842" stop-color="#6200EE" stop-opacity=".32" />
                </linearGradient>
                <filter id="a" width="324.726" height="232.344" x=".322" y="0" color-interpolation-filters="sRGB" filterUnits="userSpaceOnUse">
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
                    <feOffset dy="48.991" />
                    <feGaussianBlur stdDeviation="13.339" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                    <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_10_1946" />
                    <feBlend in="SourceGraphic" in2="effect1_dropShadow_10_1946" result="shape" />
                </filter>
            </defs>
        </svg>
    </>
}