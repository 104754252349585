import { useEffect, useRef, useContext, createContext, useState } from "react";
import { ScrollTrigger, gsap } from "gsap/all";
import Scrollbar from 'smooth-scrollbar';
import { ScrollbarContext, isTouchScreenDevice } from 'components/contexts'
import IndexNavbar from "./Navbars/IndexNavbar";

// gsap.registerPlugin(ScrollTrigger);
export default (props) => {

  const ref = useRef(null)
  const [bar, setBar] = useState(null);

  useEffect(() => {
    const scroller = document.querySelector("[data-scrollbar]");
    if (!isTouchScreenDevice()) {
      const bodyScrollBar = Scrollbar.init(scroller);
      setBar(() => bodyScrollBar);
    }

  }, []);

  // useEffect(() => {
  //   console.log(bar)
  //   ScrollTrigger.scrollerProxy("body", {
  //     scrollTop(value) {
  //       console.log(bar)
  //       if (bar)
  //         console.log(bar.scrollTop)

  //       if (arguments.length) {
  //         if (bar)
  //           bar.scrollTop = value;
  //       }
  //       if (bar)
  //         return bar.scrollTop;
  //       return 0;

  //     },
  //   });
  // }, [bar])


  return (
    <>
      <ScrollbarContext.Provider value={bar}>
        <div>
          <IndexNavbar fixed={props.fixed}></IndexNavbar>
          <div className={isTouchScreenDevice() ? "viewport touch" : "viewport"} ref={ref} data-scrollbar>
            <div className={isTouchScreenDevice() ? "viewport-container touch" : "viewport-container"}>
              {props.children}
            </div>
          </div>
        </div>
      </ScrollbarContext.Provider>
    </>
  );
}
