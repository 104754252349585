/*!

=========================================================
* BLK Design System React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useContext } from "react";
import StackLayer from "components/PageHeader/StackLayer"
import Logo from "components/logo"
import { useEffect, useLayoutEffect, useRef } from "react";
import Scrollbar from 'smooth-scrollbar';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Label,
  FormGroup,
  Form,
  Input,
  FormText,
  NavItem,
  NavLink,
  Nav,
  Table,
  TabContent,
  TabPane,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  UncontrolledCarousel
} from "reactstrap";

import { gsap, ScrollTrigger } from "gsap/all";
import { ScrollbarContext, isTouchScreenDevice } from 'components/contexts'
import { ReactComponent as FlowChart } from 'components/PageSecurity/Flow.svg';
import BlockChain from "./BlockChain";

gsap.registerPlugin(ScrollTrigger)

function createHeaderAnimation() {
  gsap.from('.page-security ', {
    opacity: 0
  })
  gsap.from('.section-1 .feature-wrapper h3', { y: 80, duration: 1, delay: 0 })
  gsap.from('.section-1 .feature-wrapper h1', { y: 120, duration: 1, delay: .3 })
  gsap.from('.section-1 .feature-wrapper p', { opacity: 0, duration: 1, delay: .5 })
  gsap.from('.section-1 ', { scale: 1.1, duration: 1, delay: .2 })


  gsap.fromTo('.section-1 ', {
    backgroundPositionY: 20
  }, {
    scrollTrigger: {
      trigger: '.section-1',
      start: 'top top',
      ends: 'bottom bottom',
      scrub: true,
      scroller: '.viewport-container'
    },
    backgroundPositionY: 10
  })

  gsap.fromTo('.section-1 .feature-2 ', {
    translateY: 600
  }, {
    scrollTrigger: {
      trigger: '.section-1',
      start: 'top top',
      ends: 'bottom bottom',
      scrub: true,
      scroller: '.viewport-container'
    },
    translateY: -100
  })

  gsap.to('.security-feature', {
    scrollTrigger: {
      trigger: '.section-1',
      start: 'top top',
      ends: 'bottom bottom',
      scrub: true,
      scroller: '.viewport-container'
    },
    backgroundPositionY: 10
  })


  gsap.timeline({
    scrollTrigger: {
      trigger: '.feature-2 .feature-wrapper h2',
      start: 'top bottom',
      end: '+=200px',
      scroller: '.viewport-container',
    }
  }).from(
    '.feature-2 .feature-wrapper h2', { y: 80, duration: .6, stagger: .1 })


  gsap.fromTo('.section-3 ', {
    backgroundPositionY: 100
  }, {
    scrollTrigger: {
      trigger: '.section-3',
      start: 'top center',
      ends: 'bottom bottom',
      scrub: true,
      scroller: '.viewport-container'
    },
    backgroundPositionY: 90
  })

  gsap.timeline({
    scrollTrigger: {
      trigger: '.feature-3 .feature-wrapper h2',
      start: 'top bottom',
      end: '+=200px',
      scroller: '.viewport-container',
    }
  }).from(
    '.feature-3 .feature-wrapper h2', { y: 80, duration: 1, stagger: .2 })
    .from(
      '.feature-3 p', { opacity: 0, x: 10, y: 10 }
    )


  gsap.fromTo('.feature-4 .colored-red', {
    scale: .8,
  }, {
    scrollTrigger: {
      trigger: '.feature-4',
      start: 'top bottom',
      ends: '+=400px',
      scrub: true,
      scroller: '.viewport-container',
    },
    scale: 1.1,
  })

  gsap.fromTo('.section-5 ', {
    backgroundPositionY: 80
  }, {
    scrollTrigger: {
      trigger: '.section-5',
      start: 'top center',
      ends: 'bottom bottom',
      scrub: true,
      scroller: '.viewport-container'
    },
    backgroundPositionY: 10
  })


  gsap.timeline({
    scrollTrigger: {
      trigger: '.feature-4 .feature-wrapper h2',
      start: 'top bottom',
      end: '+=200px',
      scroller: '.viewport-container',
    }
  }).from(
    '.feature-4 .feature-wrapper h1, .feature-4 .feature-wrapper h2, .feature-4 .feature-wrapper h4 ',
    { y: 80, duration: 1, stagger: .2 })



  gsap.timeline({
    ease: 'circ',
    scrollTrigger: {
      trigger: '.feature-5 .data-flow-chart',
      start: 'top bottom',
      scroller: '.viewport-container',
    }
  }).from(
    '.feature-5 .client-group',
    { y: -300, opacity: 0, duration: 1 }, 0)
    .from(
      '.feature-5 .securing-inbound-group',
      { x: -300, opacity: 0, duration: 1 }, .5)
    .from(
      '.feature-5 .protect-group',
      { y: -300, opacity: 0, duration: 1 }, 1)
    .from(
      '.feature-5 .securing-outbound-group',
      { x: -300, opacity: 0, duration: 1 }, 1.5)
    .from(
      '.feature-5 .pm-group',
      { y: -300, opacity: 0, duration: 1 }, 2)

}


export default function PageHeader() {
  const el = useRef();
  const q = gsap.utils.selector(el);
  const tl = useRef();
  const bar = useContext(ScrollbarContext)


  useLayoutEffect(() => {
    if (bar)
      bar.addListener((status) => {
        ScrollTrigger.update()
      });

    if (bar) {
      ScrollTrigger.scrollerProxy(".viewport-container", {
        scrollTop(value) {
          if (arguments.length) {
            if (bar)
              bar.scrollTop = value;
          }
          if (bar)

            return bar.scrollTop;
          return 0;
        },
        getBoundingClientRect() {
          return {
            top: 0,
            left: 0,
            width: window.innerWidth,
            height: window.innerHeight
          };
        }
      });

    }

    if (bar || isTouchScreenDevice())
      createHeaderAnimation()

    return () => {
      if (bar)
        ScrollTrigger.scrollerProxy(null);
    }
  }, [bar])


  // useEffect(() => {
  //   const scroller = document.querySelector(".data-flow-chart");
  //   Scrollbar.init(scroller);
  // }, []);



  return (
    <>
      <div className=" page-security">
        <Container className="section-1">
          <Row className="security-feature feature-1">
            <Col lg='1' className="">
            </Col>
            <Col lg='5' className="">
              <div className="feature-wrapper">
                <h3 >Security & Privacy by Design</h3>
              </div>
              <div className="feature-wrapper">
                <h1>Dailir Decentralized Zero-Trust Multi-Chain Architecture</h1>
              </div>
              <div className="feature-wrapper">
                <p >With security and privacy considerations in mind, we leverage the web5 infrastucture with our own in-house multi-chain
                  multi-cloud trust distribution architecture to connect all the attack vectors and
                  ensure that not a single attack vector itself can be successfully exploited.</p>
              </div>
            </Col>
            <Col lg='7'>
            </Col>
          </Row>
          <Row className="security-feature feature-2">
            <Col lg='6' >
            </Col>
            <Col lg='6' className="colored blur">
              <div className="feature-wrapper">
                <h2>
                  <i className="tim-icons icon-simple-add" />
                  Trustless Profile Sharing</h2>
              </div>
              <div className="feature-wrapper">
                <h2>
                  <i className="tim-icons icon-simple-add" />
                  Trustless Access Control </h2>
              </div>
              <div className="feature-wrapper">
                <h2>
                  <i className="tim-icons icon-simple-add" />
                  Trustless Online Transactions</h2>
              </div>
              <h4>So you don't need to trust any third parties, including us, to access and verify your client data.</h4>
            </Col>
          </Row>
        </Container>
        <Container className="section-2">
          <Row className="security-feature feature-3">
            <Col lg='1' className="colored">
            </Col>
            <Col lg='4' className="colored">
              <div className="feature-wrapper">
                <h2>
                  On-chain Access Control and Data Integrity Verification</h2>
              </div>
              <p> <i className="tim-icons icon-lock-circle" />
                We combine multiple cloud service providers with block chain smart contracts
                for data access control. A smart contract is created with a shared fully homomorphic encrypted
                AES access group key and profile digital signatures for access control and integrity verification.
                Attackers will need to access the client's, the PM's, and Dailir's credentials in
                order to modify the keys or access a specific client's data.
              </p>
            </Col>
            <Col lg='6' className="bc-holder">
              <BlockChain></BlockChain>
            </Col>
            <Col lg='1' className="colored">
            </Col>
          </Row>
        </Container>
        <Container className="section-3">
        </Container>
        <Container className="section-4">
          <Row className="security-feature feature-4">
            <Col lg='1' className="colored">
            </Col>
            <Col lg='5' className="colored-red text-right">
              <div className="feature-wrapper">
                <h1><i className="tim-icons icon-simple-add" />Dailir TensorSeal</h1>
              </div>
              <div className="feature-wrapper">
                <h2> Confidentiality and Privacy Preserving <br />Artificial Intelligence</h2>
              </div>
              <div className="feature-wrapper">
                <h4>We can conduct analysis on a client's profile. This makes it possible to suggest profiles that are looking for wealth management services and match requirements, all from encrypted data. 
    No one, including Dailir, know what the data is or the result of the outcome; only the data owner and the party which has obtained consent from the data owner can view them. </h4>
              </div>
            </Col>
            <Col lg='6' className="colored">
            </Col>

          </Row>
        </Container>
        <Container className="section-5">
          <Row className="security-feature feature-5 text-center">
            <Col lg='1' className="colored-red">
            </Col>
            <Col lg='10' className="data-flow-chart colored blur">
              <FlowChart ></FlowChart>
            </Col>
            <Col lg='1' className="colored-red">
            </Col>
          </Row>
          <Row className="security-feature feature-6 text-center">
            <Col lg='12' className="colored colored-red">
              <div className="feature-wrapper">
                <h3>
                  We will ensure that all the required standards and certifications are met before deployment.
                </h3>
              </div>
            </Col>
            <Col lg='4' className="colored ">
              <div className="feature-wrapper">
                <h2>SOC 2 Type I <br></br> Security certification</h2>
              </div>
            </Col>
            <Col lg='4' className="colored ">
              <div className="feature-wrapper">
                <h2>SOC 2 Type II <br></br> Security certification</h2>
              </div>
            </Col>
            <Col lg='4' className="colored ">
              <div className="feature-wrapper">
                <h2>ISO 27001</h2>
              </div>
            </Col>

            <Col lg='4' className="colored">
              <div className="feature-wrapper">
                <h2>GDPR <small>- compliant</small></h2>
              </div>
            </Col>
            <Col lg='4' className="colored">
              <div className="feature-wrapper">
                <h2>PHIPA <small>- compliant</small></h2>
              </div>
            </Col>
            <Col lg='4' className="colored">
              <div className="feature-wrapper">
                <h2>PIPEDA<small>- compliant</small></h2>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}
