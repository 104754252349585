/*!

=========================================================
* BLK Design System React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useContext } from "react";
import StackLayer from "components/PageHeader/StackLayer"
import Logo from "components/logo"
import { useEffect, useLayoutEffect, useRef } from "react";
import Scrollbar from 'smooth-scrollbar';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Label,
  FormGroup,
  Form,
  Input,
  FormText,
  NavItem,
  NavLink,
  Nav,
  Table,
  TabContent,
  TabPane,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  UncontrolledCarousel
} from "reactstrap";

import { gsap, ScrollTrigger } from "gsap/all";
import { ScrollbarContext, isTouchScreenDevice } from 'components/contexts'

gsap.registerPlugin(ScrollTrigger)

function createHeaderAnimation() {

  gsap.from('.brand', { y: -80, opacity: 0, duration: 1, scale: 1.5 })
  gsap.from('.brand .feature-wrapper h1', { y: -130, duration: 1, delay: 1 })
  gsap.from('.brand p', { opacity: 0, duration: 1, delay: 1.3 })
  gsap.from('.brand a', { opacity: 0, translateY: 50, duration: 1, delay: 1.7 })

}


export default function PageHeader() {
  const el = useRef();
  const q = gsap.utils.selector(el);
  const tl = useRef();
  const bar = useContext(ScrollbarContext)


  useLayoutEffect(() => {
    if (bar)
      bar.addListener((status) => {
        ScrollTrigger.update()
      });

    if (bar) {
      ScrollTrigger.scrollerProxy(".viewport-container", {
        scrollTop(value) {
          if (arguments.length) {
            if (bar)
              bar.scrollTop = value;
          }
          if (bar)

            return bar.scrollTop;
          return 0;
        },
        getBoundingClientRect() {
          return {
            top: 0,
            left: 0,
            width: window.innerWidth,
            height: window.innerHeight
          };
        }
      });

    }
    if (bar || isTouchScreenDevice())
      createHeaderAnimation()

    return () => {
      if (bar)
        ScrollTrigger.scrollerProxy(null);
    }
  }, [bar])


  // useEffect(() => {
  //   const scroller = document.querySelector(".data-flow-chart");
  //   Scrollbar.init(scroller);
  // }, []);



  return (
    <>

      <div className="page-contact">
        <div className="squares square1" />
        <div className="squares square2" />
        <div className="squares square3" />
        <div className="squares square4" />
        <div className="squares square5" />
        <div className="squares square6" />
        <div className="squares square7" />
        <Container>
          <div className="content-center brand">
            <div className="feature-wrapper">
              <h1>Hello from Montréal!</h1>
            </div>
            {/* <p>Dailir is extending invitations to stakeholders in the Hospitality Industry to discuss pain points and opportunities for partnership. We look forward to delivering our invisible payments solution to our trusted partners and removing the friction between patrons and their favourite establishments.</p> */}
            <a href="mailto:sacha.alaby@dailir.ca">Contact Us</a>
          </div>
        </Container>
      </div>
    </>
  );
}
